<template>
  <loader v-bind="{ loading }" text="Loading Checklists">
    <columns>
      <column>
        <form>
          <text-input 
            v-model="filters.search"
            classes="has-addons is-rounded"
            placeholder="Search for checklists...">
            <template #right>
              <div class="buttons has-addons is-rounded">
                <submit-button 
                  @submit="runSearch"
                  class="is-rounded">
                  Search
                </submit-button>
                <action-button
                  v-if="$route.query.search"
                  @click="clearFilters">
                  <icon icon="times"/> 
                </action-button>
              </div>
            </template>
          </text-input>
        </form>
      </column>
    </columns>
    <columns>
      <column>
        <div class="index-rows">
          <div class="box is-marginless" v-for="checklist in checklists.data" :key="checklist.id">
            <columns>
              <column>
                <router-link :to="{
                    name: 'checklist-manager',
                    params: {
                      checklist: checklist.uuid
                    }
                  }">
                  <span class="is-flex is-align-items-center">
                    #{{ checklist.id }}
                    <span v-if="checklist.work_order.reference && checklist.work_order.reference !== '--'"> - {{ checklist.work_order.reference }}</span>
                    <span class="has-text-weight-light" v-if="checklist.area"> - {{ checklist.area.name }}</span>
                  </span>
                </router-link>
                <span>Last updated {{ checklist.updated_at | asCalendar }}</span>
              </column>
              <column class="is-2 is-flex is-justify-content-right">
                <index-info 
                    label="Team" 
                    :info="checklist.work_order.team.name"
                  />
              </column>
              <column class="is-2 is-flex is-justify-content-right">
                <index-info 
                    label="Customer" 
                    :info="checklist.work_order.location.customer.name"
                  />
              </column>
              <column class="is-3 is-flex is-justify-content-right">
                <index-info 
                    label="Location" 
                    :info="`${checklist.work_order.location.name}`"
                  />
              </column>
            </columns>
          </div>
        </div>

        <pager v-if="checklists.data.length" :pageable="checklists" context="Checklist" jump-controls @nav="goToPage" />

        <no-items-to-display
          :item-count="checklists.data.length"
          heading="There are no checklists available for display."
        />

      </column>
    </columns>
  </loader>
</template>
<script>
import { get } from '@/api/request'
export default {

  data: () => ({
    loading: true,
    filters: {
      search: ''
    },
    checklists: {
      data: []
    }
  }),

  created() {
    if(this.$route.query) {
      this.filters.search = this.$route.query.search
    }
    this.loadChecklists()
  },

  methods: {
    loadChecklists() {
      get('v1' + this.$route.fullPath, ({ data }) => {
        this.checklists = data
        this.loading = false
      }, () => {
        this.loading = false
      }, { params: this.filters })
    },
    goToPage(path) {
      this.$router.push(path)
    },
    runSearch() {
      this.$router.push({
        name: 'checklist-template-checklists',
        params: {
          checklistTemplate: this.$route.params.checklistTemplate
        },
        query: this.filters
      });
    },
    clearFilters() {
      this.filters.search = ''
      this.$router.push({
        params: {
          checklistTemplate: this.$route.params.checklistTemplate
        },
        name: 'checklist-template-checklists',
      });
    }
  },

  watch: {
    '$route': 'loadChecklists'
  }

}
</script>